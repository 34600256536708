import { toNumber } from "lodash-es";
import Vue from "@/helper/initVue";
import Lightbox from "@/components/Lightbox.vue";

const wrapperElements = document.querySelectorAll(".v-lightbox__wrapper");

Array.from(wrapperElements).forEach((wrapper) => {
  const lightboxElement = wrapper.querySelector(".v-lightbox");
  if (!lightboxElement) {
    return;
  }

  const imageWrappers = Array.from(wrapper.querySelectorAll(".image__wrapper"));
  const images: Record<string, unknown>[] = [];
  const overlays: Element[] = [];

  Array.from(imageWrappers).forEach((imageWrapper) => {
    const lightboxItems = Array.from(
      imageWrapper.querySelectorAll(".v-lightbox__item")
    );
    const lightboxOverlays = Array.from(
      imageWrapper.querySelectorAll(".v-lightbox__overlay")
    );

    if (lightboxItems.length === 0 || lightboxOverlays.length === 0) {
      return;
    }

    try {
      overlays.push(lightboxOverlays[0]);

      const dataImage = lightboxItems[0].getAttribute("data-image");
      if (dataImage) {
        const formattedDataImage = dataImage.replace("&qout;", '"');

        const parsedData = JSON.parse(formattedDataImage);
        parsedData.index = toNumber(parsedData.index);

        if (parsedData) {
          images.push(parsedData);
        }
      }
    } catch (err) {
      // nothing to do here
    }
  });

  if (images.length === 0) {
    return;
  }

  new Vue({
    el: lightboxElement,
    render: (h) =>
      h(Lightbox, {
        props: {
          overlays,
          images,
        },
      }),
  });
});
